#nav {
	font-size: 1.2em;
	gap: 18px;
	justify-content: space-around;
	grid-area: nav;
	max-height: calc(100vh - var(--nav-height, 4rem));
	overflow: auto;
}

#nav > .nav-btn {
	background-color: transparent;
	color: inherit;
	border: none;
}

#install-btn > svg {
	margin-bottom: 10px;
}

@media (max-width: 450px) {
	#nav .grow-4 {
		display: none;
	}
}
