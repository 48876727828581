#product-gallery.grid {
	grid-template-columns: repeat(auto-fill, minmax(20vw, 400px));
	gap: 12px;
	justify-content: space-evenly;
}

a.btn {
	text-decoration: inherit;
}

a.btn.btn-primary:hover {
	color: var(--button-primary-color);
}
